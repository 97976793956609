<template>
  <v-container fluid>
    <v-row>
      <v-col cols="4">
        <v-card class="mx-auto rounded-lg" outlined>
          <v-list-item three-line>
            <v-list-item-content>
              <div class="mb-4">
                <h5 color="black--text">TOTAL PLANES {{year}}</h5>
              </div>
              <v-list-item-title class="text-h5 mb-1" color="primary">
                <h6 color="black--text">{{dashboardData.total}}</h6>
              </v-list-item-title>
              <v-list-item-subtitle></v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar
              tile
              size="50"
              color="#C1ECCB"
              class="rounded-circle"
            >
              <v-icon size="35">mdi-check</v-icon>
            </v-list-item-avatar>
          </v-list-item>
          <v-card-actions>
            <h6>
            </h6>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card class="mx-auto" outlined>
          <v-list-item three-line>
            <v-list-item-content>
              <div class="mb-4">
                <h5 color="black--text">ESTIMACIÓN COSTO PLANES {{year}}</h5>
              </div>
              <v-list-item-title class="text-h5 mb-1" color="primary">
                <h6 color="black--text">{{dashboardData.total_amount | currencyCOP}}</h6>
              </v-list-item-title>
              <v-list-item-subtitle></v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-avatar
              tile
              size="50"
              color="#C1ECCB"
              class="rounded-circle"
            >
              <v-icon size="35">mdi-chart-line</v-icon>
            </v-list-item-avatar>
          </v-list-item>
          <v-card-actions>
            <h6>

            </h6>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card class="mx-auto" outlined>
          <v-list-item three-line>
            <v-list-item-content>
              <div class="mb-4">
                <h5 color="black--text">TOTAL EJECUCIÓN {{year}}</h5>
              </div>
              <v-list-item-title class="text-h5 mb-1" color="primary">
                <h6 color="black--text">{{dashboardData.total_advance}}%</h6>
              </v-list-item-title>
              <v-list-item-subtitle></v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar
              tile
              size="50"
              color="#C1ECCB"
              class="rounded-circle"
            >
              <v-icon size="35">mdi-handshake</v-icon>
            </v-list-item-avatar>
          </v-list-item>
          <v-card-actions>
            <h6>
            </h6>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card min-height="350">
          <v-card-title>
          </v-card-title>
          <v-card-text class="pa-5">
            <v-toolbar flat>
              <v-toolbar-title>ÚLTIMOS PLANES REGISTRADOS</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                dark
                rounded
                class="mb-2"
                @click="redirectPlans()"
              >
                Ver todos
              </v-btn>
            </v-toolbar>

            <div class="mt-4">
              <template>
                <v-data-table
                  :headers="headers"
                  :items="plans"
                  :items-per-page="5"
                  class="elevation-1"
                >
                  <template v-slot:item.categories="{ item }">
                    <v-chip
                      v-for="(category, index) in item.categories"
                      color="#D3E9D7"
                      :key="index"
                      style="height: auto; margin-top: 11px; margin-left: 5px"
                    >
                      <span style="color: #222222; font-size: 12px">{{
                        category.name
                      }}</span>
                    </v-chip>
                  </template>
                </v-data-table>
              </template>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { listPlansResponse, getDashboardData } from "@/services/Planning";
import dayjs from "dayjs";
export default {
  data() {
    return {
      plans: [],
      headers: [
        {
          text: "ID",
          align: "start",
          sortable: false,
          value: "id",
        },
        { text: "FECHA", value: "created_at" },
        { text: "CATEGORÍA", value: "categories" },
        { text: "TERRITORIO", value: "territory.descripcion" },
        { text: "NIVEL", value: "mandate.name" },
        { text: "AÑO MANDATO", value: "mandate_year" },
      ],
      dashboardData: {},
    };
  },
  methods: {
    redirectPlans() {
      this.$router.push({ name: "planning.plans" });
    },
  },
  async created() {
    try {
      this.showLoader();
      const response = await listPlansResponse({ filters: { limit: 5 } });
      this.plans = response.data;
      this.dashboardData = (await getDashboardData()).data;
      this.hideLoader();
    } catch (error) {
      this.hideLoader();
      console.log(error);
    }
  },
  computed :{
    year(){
      return dayjs().format('YYYY')
    }
  }
};
</script>
